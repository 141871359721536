// 2週間以内かどうか
export const isNewPost = ({
  postDate,
  currentDate,
}: {
  postDate: string;
  currentDate: string;
}) => {
  const now = new Date(currentDate);
  const post = new Date(postDate);
  const diff = now.getTime() - post.getTime();
  return diff < 1000 * 60 * 60 * 24 * 14;
};
