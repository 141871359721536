// Gtag cmd
export const GTAG_CMD_EVENT = 'event';

// Gtag event names
export const GTAG_EVENT_NAMES = {
  IMP_BANNER_Z: 'imp_banner_z',
  IMP_TOP_BANNER: 'imp_top_banner',
  IMP_BOTTOM_BANNER: 'imp_bottom_banner',
  IMP_INDUSTRY_RECRUITING_ITEMS: 'imp_industry_recruiting_items',
  SAVE_PROFILE_INTERNS: 'save_profile_interns',
  SAVE_PROFILE_DEVELOPMENT_EXPERIENCE: 'save_profile_development_experience',
  SAVE_PROFILE_OFFERS: 'save_profile_offers',
  CLICK_INDUSTRY_RECRUITING_ITEMS: 'click_industry_recruiting_items',
} as const;

// Location: ページ単位で定義する
// https://www.notion.so/howtv/ffdaa6d4dee743c3b78fa485087d2228?v=9f6040757e5c411fb716d9d32ab45883&pvs=4
export const GTAG_LOCATION = {
  TOP: 'TOP',
  COMPANY_REPORTS: '/reports',
  SELECTION_REPORT: '/selection_report/[id]',
  PASSED_ES: '/passed_es/[id]',
  COLUMN_CATEGORIES: '/column/categories',
  TAG_COLUMN_LIST: '/column/tag/[tagName]',
  SUB_CATEGORY_COLUMN_LIST: '/column/category/[categoryName]/[subCategoryName]',
} as const;

// Make above object values type
export type GtagLocation = (typeof GTAG_LOCATION)[keyof typeof GTAG_LOCATION];
