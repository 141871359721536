import {Box} from '@mantine/core';
import {
  GoogleAdBannerMap,
  type GoogleAdBannerName,
} from '../../../config/constants/googleAdBanner';

type Props = {
  googleAdBannerName: GoogleAdBannerName;
};

export const GoogleAdBanner: React.FC<Props> = ({googleAdBannerName}) => {
  return <Box id={GoogleAdBannerMap[googleAdBannerName].id} />;
};
