import {z} from 'zod';
import {
  inputTypeList,
  REPORT_TYPE_OPTIONAL_INTERN,
  reportTypeList,
} from './constants';

const ReportTypeSchema = z.object({
  key: z.string(),
  name: z.string(),
});
export const ReportTypesResponse = z.object({
  types: z.array(ReportTypeSchema),
});

const IndustryCategorySchema = z.object({
  id: z.number(),
  name: z.string(),
  shortName: z.string(),
});
export const IndustryCategoriesResponse = z.object({
  industryCategories: z.array(IndustryCategorySchema),
});

const DepartmentMasterSchema = z.object({
  id: z.number(),
  name: z.string(),
});
export const DepartmentMasterResponse = z.array(DepartmentMasterSchema);

const ReportsCountSchema = z.object({
  industryCategoryName: z.string(),
  companyList: z.array(
    z.object({
      companyID: z.number(),
      companyName: z.string(),
      industryName: z.string(),
      counts: z.object({
        selectionCount: z.number(),
        internCount: z.number(),
        entrysheetCount: z.number(),
      }),
      isNew: z.boolean(),
    }),
  ),
});
export const ReportsCountResponse = z.array(ReportsCountSchema);

export const ReportSchema = z.object({
  id: z.number(),
  type: z.string(),
  publishedAt: z.string(),
  industryCategoryID: z.number(),
  companyID: z.number(),
  companyName: z.string(),
  departmentID: z.number(),
  departmentName: z.string(),
  departmentCategory: z.object({
    id: z.number(),
    name: z.string(),
    order: z.number(),
  }),
  eventName: z.string(),
  excerpt: z.string(),
  detailURL: z.string(),
  clipsCount: z.number(),
  _recentClipsCount: z.number(),
  reporterGraduationYear: z.number(),
  reporterGetOffer: z.boolean(),
  isNew: z.boolean(),
  clipped: z.boolean(),
  isEntrySheet: z.boolean(),
});
export const ReportsResponse = z.object({
  reports: z.array(ReportSchema),
  order: z.string(),
  industryCategoryIDs: z.array(z.number()),
  types: z.array(z.string()),
  totalCount: z.number(),
  page: z.number(),
  limit: z.number(),
  company: z.string(),
  companyIDs: z.array(z.number()),
  recruitmentIDs: z.array(z.number()),
});

const CompanyDepartmentMasterSchema = z.object({
  id: z.number(),
  name: z.string(),
});
export const ComapanyDepartmentMasterResponse = z.array(
  CompanyDepartmentMasterSchema,
);

const fixedQuestionAnswersSchema = z.object({
  id: z.number(),
  questionID: z.number(),
  questionLabel: z.string(),
  answerID: z.number(),
  answerLabel: z.string(),
});

export const getEntrySheetSchema = z.object({
  entrysheetID: z.number(),
  nextEntrySheetID: z.number().nullable(),
  previousEntrySheetID: z.number().nullable(),
  userID: z.number(),
  graduationYear: z.number(),
  status: z.enum([
    'published',
    'notFinished',
    'returnedBack',
    'accepted',
    'underChecking',
    'invalid',
  ]),
  companyID: z.number(),
  companyName: z.string(),
  industryID: z.number(),
  selectionReportType: z.enum([...reportTypeList, REPORT_TYPE_OPTIONAL_INTERN]), // 本来は使われないはずだがエラー回避のため一旦追加 https://github.com/howtv/gsskt_issues/issues/7160
  eventName: z.string(),
  note: z.string(),
  questionAnswers: z.array(
    z.object({
      id: z.number(),
      question: z.string(),
      answer: z.string(),
      remarks: z.string(),
      order: z.number(),
      deleted: z.boolean(),
    }),
  ),
  getOffer: z.boolean(),
  isClipped: z.boolean(),
  clipCount: z.number(),
  submissionDueDate: z.string(),
  department: z.object({
    id: z.number(),
    name: z.string(),
  }),
  fixedQuestionAnswers: z.object({
    resultNotificationMethod: z.array(fixedQuestionAnswersSchema),
    resultNotificationTime: z.array(fixedQuestionAnswersSchema),
    submissionMethod: z.array(fixedQuestionAnswersSchema),
  }),
});

export const getEntrySheetsSchema = z.array(getEntrySheetSchema);

const QuestionAnswerSchema = z.object({
  question: z.string(),
  answer: z.string(),
  inputType: z.enum(inputTypeList),
});

export type QuestionAnswerType = z.infer<typeof QuestionAnswerSchema>;

const SectionListSchema = z.object({
  id: z.number(),
  sectionName: z.string(),
  questionAnswerList: z.array(QuestionAnswerSchema),
});

const QuestionListSchema = z.object({
  id: z.number(),
  sectionName: z.string(),
  questionAnswerList: z.array(QuestionAnswerSchema),
});

export const getSelectionReportSchema = z.object({
  reportId: z.number(),
  nextReportId: z.number().nullable(),
  previousReportId: z.number().nullable(),
  graduationYear: z.number(),
  company: z.object({
    id: z.number(),
    name: z.string(),
  }),
  department: z.object({
    id: z.number(),
    name: z.string(),
  }),
  selectionType: z.enum(reportTypeList),
  getOffer: z.boolean(),
  isClipped: z.boolean(),
  clipCount: z.number(),
  eventName: z.string(),
  sectionList: z.array(SectionListSchema),
  stepList: z.array(QuestionListSchema),
});

const GetReportsItemSchema = z.object({
  id: z.number(),
  type: z.enum(reportTypeList),
  publishedAt: z.string(),
  industryCategoryID: z.number(),
  companyID: z.number(),
  companyName: z.string(),
  departmentName: z.string(),
  departmentCategory: z.object({
    id: z.number(),
    name: z.string(),
    order: z.number(),
  }),
  eventName: z.string(),
  excerpt: z.string(),
  detailURL: z.string(),
  clipsCount: z.number(),
  _recentClipsCount: z.number(),
  reporterGraduationYear: z.number(),
  reporterGetOffer: z.boolean(),
  isNew: z.boolean(),
  clipped: z.boolean(),
  isEntrySheet: z.boolean(),
});

export const getReportListSchema = z.object({
  reports: z.array(GetReportsItemSchema),
});
