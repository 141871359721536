// ES・体験記の企業別一覧ページのSP画面などで、4番目のカードの後ろにトップバナー、7番目のカードの後ろにボトムバナーを表示するための関数。
export function getTopBottomBannerPosition(
  index: number,
): 'top' | 'bottom' | undefined {
  const topBannerPosition = 3;
  const bottomBannerPosition = 6;
  const positions: Record<number, 'top' | 'bottom'> = {
    [topBannerPosition]: 'top',
    [bottomBannerPosition]: 'bottom',
  };
  return positions[index];
}
