import {Box} from '@mantine/core';
import React from 'react';
import {
  LOGO_RANKING_GOLD,
  LOGO_RANKING_SILVER,
  LOGO_RANKING_BRONZE,
} from '../../../config/constants/followCompanyRanking';

export const RenderRankingNumber = (rank: number) => {
  const rankNumberInImg = (rank: number) => {
    return (
      <Box className="absolute left-[5px] top-[20px] float-left font-bold text-white">
        {rank}
      </Box>
    );
  };

  switch (rank) {
    case 1: {
      return (
        <Box className="relative top-[-5px] h-[50px] w-[40px] items-center">
          <img
            src={LOGO_RANKING_GOLD}
            className="absolute left-[-9px] float-left h-[50px] w-[40px]"
          />
          {rankNumberInImg(rank)}
        </Box>
      );
    }

    case 2: {
      return (
        <Box className="relative top-[-5px] h-[50px] w-[40px] items-center">
          <img
            src={LOGO_RANKING_SILVER}
            className="absolute left-[-9px] float-left h-[50px] w-[40px]"
          />
          {rankNumberInImg(rank)}
        </Box>
      );
    }

    case 3: {
      return (
        <Box className="relative top-[-5px] h-[50px] w-[40px] items-center">
          <img
            src={LOGO_RANKING_BRONZE}
            className="absolute left-[-9px] float-left h-[50px] w-[40px]"
          />
          {rankNumberInImg(rank)}
        </Box>
      );
    }

    default: {
      return (
        <Box className="relative top-[-5px] h-[50px] w-[40px] items-center">
          <Box className="absolute left-[5px] top-[20px] float-left font-bold text-black-2">
            {rank}
          </Box>
        </Box>
      );
    }
  }
};
