import {
  type ReportsReturnValueType,
  type ReportsResponse,
} from '../../repositories/reportsRepository';
import {ReportType} from '../../entities/reports/constants';

export function getReportsFromPages(
  data: ReportsReturnValueType | undefined,
): ReportsResponse | undefined {
  if (!data?.ok) return undefined;
  const reports = data.value.reports
    .filter(
      (report): report is Exclude<typeof report, undefined> => report !== null,
    )
    .map((report) => {
      if (report.isEntrySheet) {
        return {
          ...report,
          type: ReportType.ES,
        };
      }

      return report;
    });

  return {
    ...data.value,
    reports,
  };
}
