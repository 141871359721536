import {MdExplore} from 'react-icons/md';
import {CommonLink} from '../../../../shared/components/CommonLink';
import {useMediaQuery} from '../../../../shared/services/useMediaQuery';

export const FindColumnByKeyword: React.FC = () => {
  const {isPC} = useMediaQuery();
  return (
    <div className={`bg-white ${isPC ? `p-32` : `p-4`} my-12`}>
      <div className="pb-16">
        <div className="font-bold text-xl pb-12 flex items-center">
          <MdExplore size="21" className="mr-8" />
          {isPC
            ? '定番の就活コラムをキーワードで見つける'
            : '定番の就活コラムを見つける'}
        </div>
        <div
          className={`text-sm text-black-2 ${isPC ? `` : `whitespace-pre-line`}`}
        >
          働きたい業界、気になる企業、なりたい職種、自分の属性、選考対策など、気になる就活キーワードから、ダイレクトで就活コラムをチェックできます。見落としていた定番就活コラムを見つけるきっかけになるかもしれません。就活は自分の軸や業界分析、企業分析、職種の分析だけではなく、偶然の縁が大切な時も。先輩内定者に人気だった定番就活対策コラムや就活の転機になった就活コラムまで、独自の視点で記された就活記事で、外資系企業や日系トップ企業の内定を獲得しよう。
        </div>
      </div>
      <div>
        <div className="font-bold text-lg pb-12">
          業界から定番就活コラムを見つける
        </div>
        <div className="text-sm pb-12 text-black-2">
          就活生に人気な外資金融、商社、コンサルを始めとして、人気の業界キーワードから就活コラムを見つけましょう。
        </div>
        <div className={isPC ? `flex` : `block`}>
          <div
            className={`${isPC ? `w-[25%] pr-32` : `w-[100%] bg-black-5 p-8 mb-8`}`}
          >
            <div className={`${isPC ? `font-bold` : ``} text-link pb-12`}>
              <CommonLink
                href="/archives/133655"
                className={
                  isPC
                    ? ``
                    : `border border-primary-1 px-32 py-4 rounded-4 bg-white text-sm`
                }
              >
                外銀
              </CommonLink>
            </div>
            <div className={`text-sm  text-black-2 ${isPC ? `pb-12` : ``}`}>
              投資銀行の現場で働いていたリアルな声をインタビューしました。投資銀行への就職活動や業務内容についてまとまっています。
            </div>
          </div>
          <div
            className={`${isPC ? `w-[25%] pr-32` : `w-[100%] bg-black-5 p-8 mb-8`}`}
          >
            <div className={`${isPC ? `font-bold` : ``} text-link pb-12`}>
              <CommonLink
                href="/archives/133655"
                className={
                  isPC
                    ? ``
                    : `border border-primary-1 px-32 py-4 rounded-4 bg-white text-sm`
                }
              >
                商社
              </CommonLink>
            </div>
            <div className="text-sm pb-12 text-black-2">
              総合商社への就職活動を行う前提として、商社では何をやっているのか知ること、商社を研究することが重要です。
            </div>
          </div>
          <div
            className={`${isPC ? `w-[25%] pr-32` : `w-[100%] bg-black-5 p-8 mb-8`}`}
          >
            <div className={`${isPC ? `font-bold` : ``} text-link pb-12`}>
              <CommonLink
                href="/archives/133655"
                className={
                  isPC
                    ? ``
                    : `border border-primary-1 px-32 py-4 rounded-4 bg-white text-sm`
                }
              >
                コンサル
              </CommonLink>
            </div>
            <div className="text-sm pb-12 text-black-2">
              コンサルとして働く醍醐味はどこにあるのでしょうか？現役戦略コンサルタントにお聞きしました。
            </div>
          </div>
          <div
            className={`${isPC ? `w-[25%]` : `w-[100%] bg-black-5 p-4`} pr-32`}
          >
            {isPC && <div className="font-bold pb-12">他の業界</div>}
            <div className={`text-sm  text-link pb-12 ${isPC ? `` : `flex`}`}>
              <CommonLink
                href="/archives/36060"
                className={
                  isPC
                    ? `mr-8`
                    : `border border-primary-1 px-4 py-4 rounded-4 mx-4 block text-sm whitespace-nowrap bg-white`
                }
              >
                ITコンサル
              </CommonLink>
              <CommonLink
                href="/archives/144591"
                className={
                  isPC
                    ? `mr-8`
                    : `border border-primary-1 px-4 py-4 rounded-4 mx-4 block text-sm whitespace-nowrap bg-white`
                }
              >
                広告{` `}就活
              </CommonLink>
              <CommonLink
                href="/archives/19859"
                className={
                  isPC
                    ? `mr-8`
                    : `border border-primary-1 px-4 py-4 rounded-4 mx-4 block text-sm whitespace-nowrap bg-white`
                }
              >
                官公庁{` `}就活
              </CommonLink>
              <CommonLink
                href="/archives/65898"
                className={
                  isPC
                    ? `mr-8`
                    : `border border-primary-1 px-4 py-4 rounded-4 mx-4 block text-sm whitespace-nowrap bg-white`
                }
              >
                ベンチャー{` `}就活
              </CommonLink>
            </div>
            {!isPC && (
              <div className="text-sm  text-black-2">
                その他、様々な業界のコラムも見てみましょう。
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={isPC ? `flex` : `block py-16`}>
        <div className={isPC ? `w-[50%] pb-16` : `pb-16`}>
          <div className="font-bold text-lg pb-12">
            企業から定番就活コラムを見つける
          </div>
          <div className="text-sm pb-12 text-black-2">
            有名企業の裏側は気になるところ。実際に働く方や内定者へのリサーチやインタビューを通じて理解を深めましょう。
          </div>
          <div className={`text-sm text-link pb-12 ${isPC ? `` : `flex`}`}>
            <CommonLink
              href="/archives/144586"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4`
              }
            >
              google 新卒
            </CommonLink>
            <CommonLink
              href="/archives/124923"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4`
              }
            >
              電通 博報堂
            </CommonLink>
            <CommonLink
              href="/archives/3393"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4`
              }
            >
              Apple 新卒採用
            </CommonLink>
          </div>
        </div>
        <div className={isPC ? `pb-16 w-[50%]` : ``}>
          <div className="font-bold text-lg pb-12">
            職種から定番就活コラムを見つける
          </div>
          <div className="text-sm pb-12 text-black-2">
            どんな職種に興味を持っていますか？さまざまな職種の人から見た業界についてや就活について、さらに具体的に考えていきましょう。
          </div>
          <div className={isPC ? `text-sm text-link pb-12` : `flex flex-wrap`}>
            <CommonLink
              href="/archives/82659"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              アクチュアリー
            </CommonLink>
            <CommonLink
              href="/archives/5742"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4  mb-4 whitespace-nowrap text-link-1`
              }
            >
              クオンツ
            </CommonLink>
            <CommonLink
              href="/archives/43981"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              アセマネ
            </CommonLink>
            <CommonLink
              href="/archives/161120"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowra text-link-1`
              }
            >
              システムエンジニア 就職
            </CommonLink>
            <CommonLink
              href="/archives/136346"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              マーケター
            </CommonLink>
          </div>
        </div>
      </div>
      <div className={isPC ? `flex` : `py-16`}>
        <div className={isPC ? `pb-16 w-[50%]` : `pb-16`}>
          <div className="font-bold text-lg pb-12">
            属性から定番就活コラムを見つける
          </div>
          <div className="text-sm pb-12 text-black-2">
            あなたはどんな人でしょうか？自己分析を踏まえた自分の特徴から、就活に対する戦略を考えていく就活コラムを見つけてください。
          </div>
          <div className={isPC ? `text-sm text-link pb-12` : `flex`}>
            <CommonLink
              href="archives/142489"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              就活 女子
            </CommonLink>
            <CommonLink
              href="/archives/6380"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              体育会系
            </CommonLink>
            <CommonLink
              href="/archives/174398"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              院生就活
            </CommonLink>
          </div>
        </div>
        <div className={isPC ? `pb-16 w-[50%]` : `pb-16`}>
          <div className="font-bold text-lg pb-12">
            選考対策から定番就活コラムを見つける
          </div>
          <div className="text-sm pb-12 text-black-2">
            外資就活ドットコムにおける真髄とも言える、就職活動の選考対策コラムで見逃しはないですか？ぜひチェックしましょう。
          </div>
          <div className={isPC ? `text-sm text-link pb-12` : `flex flex-wrap`}>
            <CommonLink
              href="/archives/169608"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              自己分析
            </CommonLink>
            <CommonLink
              href="/archives/113274"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              志望動機
            </CommonLink>
            <CommonLink
              href="/archives/112378"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              リクルーター
            </CommonLink>
            <CommonLink
              href="/archives/81962"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              フェルミ推定
            </CommonLink>
            <CommonLink
              href="/archives/8185"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              グループディスカッション
            </CommonLink>
            <CommonLink
              href="/archives/5868"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              集団面接
            </CommonLink>
            <CommonLink
              href="/archives/167207"
              className={
                isPC
                  ? `mr-8`
                  : `border border-primary-1 px-8 py-4 rounded-4 bg-white text-sm block mx-4 mb-4  whitespace-nowrap text-link-1`
              }
            >
              合同説明会
            </CommonLink>
          </div>
        </div>
      </div>
    </div>
  );
};
