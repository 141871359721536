import {z} from 'zod';

export enum ReportType {
  SELECTION = 'selection',
  INTERN = 'intern',
  ES = 'entrysheet',
  SPRING = 'spring',
  SUMMER = 'summer',
  AUTUMN = 'autumn',
  WINTER = 'winter',
  OTHER = 'other',
}

export const ReportCategoryTypes = {
  types: [
    {
      id: 1,
      key: 'selection',
      name: '本選考',
    },
    {
      id: 2,
      key: 'intern',
      name: 'インターン',
    },
    {
      id: 3,
      key: 'entrysheet',
      name: 'エントリーシート',
    },
  ],
};

export enum Crumb {
  HOME = 'Home',
  REPORTS = 'ES・体験記',
}

export enum FollowCompanies {
  HOME = 'Home',
  Search = '企業を探す',
  Follow = '企業をフォロー',
}

export enum FilterItemKey {
  REPORT_TYPE = 'レポートの種類',
  INDUSTRY = '業界',
  DEPARTMENT = '部門・職種',
}

export const ReportCardTypes: {
  [key in ReportType]: string;
} = {
  [ReportType.SELECTION]: '本選考',
  [ReportType.INTERN]: 'インターン',
  [ReportType.SPRING]: '春インターン',
  [ReportType.SUMMER]: '夏インターン',
  [ReportType.AUTUMN]: '秋インターン',
  [ReportType.WINTER]: '冬インターン',
  [ReportType.ES]: 'エントリーシート',
  [ReportType.OTHER]: 'その他',
};

export enum ReportTagType {
  INTERN = 'インターン',
  OFFERED = '内定',
  REJECTED = '最終落ち',
}

export const COMPANY_NAME_MAX_LENGTH_PC = 14;
export const COMPANY_NAME_MAX_LENGTH_PC_WITH_NEW = 12;
export const COMPANY_NAME_MAX_LENGTH_MOBILE = 13;
export const DEPARTMENT_NAME_MAX_LENGTH = 18;
export const DEPARTMENT_CATEGORY_NAME_MAX_LENGTH = 15;

export enum ReportStatus {
  PUBLISH = 'published',
  NOT_FINISHED = 'notFinished',
  RETURNED_BACK = 'returnedBack',
  ACCEPTED = 'accepted',
  UNDER_CHECKING = 'underChecking',
}

export const OTHER = 'その他';

export const reportTypeList = [
  'selection',
  'spring',
  'summer',
  'autumn',
  'winter',
  'etc',
] as const;

export const REPORT_TYPE_OPTIONAL_INTERN = 'intern'; // 本来は使われないはずだがエラー回避のため一旦追加 https://github.com/howtv/gsskt_issues/issues/7160

export const reportTypeToJpName = {
  selection: '本選考',
  spring: '春インターン',
  summer: '夏インターン',
  autumn: '秋インターン',
  winter: '冬インターン',
  etc: 'その他のインターン',
  [REPORT_TYPE_OPTIONAL_INTERN]: 'その他のインターン', // 本来は使われないはずだがエラー回避のため一旦追加 https://github.com/howtv/gsskt_issues/issues/7160
} as const;

export const inputTypeList = ['text', 'textarea', 'radio', 'checkbox'] as const;
const inputTypeEnumSchema = z.enum(inputTypeList);
export type InputTypes = z.infer<typeof inputTypeEnumSchema>;

// For selection-report detail page
export const JOB_INFO_SECTION_INDEX = 0;
export const PREFERENCE_SECTION_INDEX = 1;
export const SELECTION_STEP_SECTION_INDEX = 2;
export const OB_VISIT_SECTION_INDEX = 3;

export const JOB_INFO_ID = 1;
export const INTERN_INFO_ID = 5;
export const PREFERENCE_ID = 8;
