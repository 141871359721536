import {MdWarning} from 'react-icons/md';

export const Announcement = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="text-center mb-12 pc:p-12 p-4">
        <div className="text-red-500 font-bold">
          <MdWarning size={16} className="inline-block relative" />
          障害のお知らせ
        </div>
        <div className="ml-2 pc:text-base text-sm text-gray-700 text-center pc:px-16 px-4">
          8月5日
          12時〜15時にサービスが停止し、一部データが欠損した可能性があります。
          該当データ（エントリー、会員登録、ES・体験記投稿）をご確認ください。
          <br />
          ご迷惑をおかけし、申し訳ございません。
        </div>
      </div>
    </div>
  );
};
