import Link from 'next/link';
import {FaCalendarCheck} from 'react-icons/fa6';
import {CommonLink} from '../../../../../shared/components/CommonLink';

export const NoLoggedInEventGuide: React.FC = () => {
  return (
    <div className="bg-white mt-16 px-32 py-24">
      <div className="flex items-center text-xl font-bold">
        <FaCalendarCheck className="mr-8" />
        外資就活ドットコム主催の特別なイベントが随時開催
      </div>
      <div className="text-sm text-black-2 my-16">
        <div>
          エントリーするだけでトップ企業から特別なオファーやスカウトを受け取れるイベントを随時開催しています。
        </div>
        <div>
          開催中のイベントは、
          <CommonLink
            href={`https://event.gaishishukatsu.com/`}
            className="hover:underline text-link"
          >
            こちらのページ
          </CommonLink>
          をご確認ください
        </div>
      </div>
    </div>
  );
};
