import {Box} from '@mantine/core';
import {type PropsWithChildren} from 'react';
import isAppWebView from '../../../config/isAppWebView';
import {Footer} from '../Footer';
import {Header} from '../Header';

export const NoLeftSidebarLayout = ({children}: PropsWithChildren) => {
  return (
    <>
      <div>
        {!isAppWebView() && <Header />}
        <main>
          <Box className="flex min-h-screen flex-col items-center bg-background-1 py-12 pc:py-16">
            {children}
          </Box>
        </main>
        {!isAppWebView() && <Footer />}
      </div>
    </>
  );
};
